//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import { Hooper, Slide } from 'hooper'
import _ from 'lodash'
import Breadcrumbs from '~/components/UI/Breadcrumbs.vue'
import Toggler from '~/components/UI/Toggler.vue'
import ProductPrice from '~/components/UI/ProductPrice.vue'

export default {
  name: 'Compare',
  components: {
    Toggler,
    Hooper,
    Slide,
    Breadcrumbs,
    ProductPrice,
  },
  middleware: ['auth'],
  async asyncData({ $api, store, localePath }) {
    store.commit('bc/SET_BC', [
      { name: 'Compare', slug: localePath({ name: 'Compare' }) },
    ])
    try {
      const response = await $api.compare.getCompare()
      const comparesList = response.data
      comparesList.attributes.forEach(difference => {
        comparesList.compares.forEach((compare, i) => {
          if(compare.item.attributes.find((item) => item.name !== difference)) {
            comparesList.compares[i].item.attributes.push(
              {
                name: difference,
                value: '-',
              },
            )
          }
        })
      })
      return {
        compares: comparesList,
        itemsToShow: comparesList.compares.length <= 2 ? 2 : 3,
      }
    } catch (error) {
      // console.log(error)
    }
  },
  data() {
    return {
      fixedHeader: false,
      sizeHeader: false,
      visibleTable: false,
      compares: null,
      itemsToShow: null,
      differences: false,
    }
  },
  computed: {
    ...mapState({
      settings: state => state.settings.settings,
    }),
    visibleHooperBtn() {
      return this.compares.compares.length > 2 && this.itemsToShow === 2 ||
        this.compares.compares.length > 3 && this.itemsToShow === 3
    },
    isMobile() {
      return this.$store.getters['device/IS_MOBILE']
    },
    productsInCart() {
      return this.$store.getters['cart/getProductsInCart']
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
    this.$nextTick(() => {
      this.visibleTable = document.getElementById('compare__table')
    })
  },
  methods: {
    isItemInCart(slug) {
      return _.find(this.productsInCart, { slug })
    },

    isItemBtnDisabled(slug) {
      return !!_.find(this.productsInCart, { slug })
    },

    favoriteStroke(id) {
      return id ? this.$color.destructiveRed : this.$color.dark
    },
    favoriteFill(id) {
      return id ? this.$color.destructiveRed : 'none'
    },
    nameAttribute(compare, difference) {
      const attribute = compare.item.attributes.find((item) => item.name === difference)
      return attribute
    },
    compareDiffers(compare, attribute) {
      const compareItem = this.compares.differences[compare.name]
      if(compare.item.attributes.length) {
        return compareItem[attribute].differs
      }
    },
    async removeAll() {
      try {
        await this.$api.compare.deleteCompareAll()
        this.compares.compares = []
        this.$store.commit('compares/REMOVE_ALL')
        this.$router.push({ path: '/'})
      } catch (error) {
        // console.log(error)
      }
    },
    async removeProduct(id, slug) {
      try {
        await this.$api.compare.deleteCompare(id)
        this.itemsToShow = this.compares.compares.length <= 2 ? 2 : 3
        this.compares.compares = this.compares.compares.filter(compare => compare.id !== id)
        this.$store.commit('compares/REMOVE_COMPARE', slug)
      } catch (error) {
        // console.log(error)
      }
    },
    slidePrev() {
      this.$refs.carousel.slidePrev()
      this.$refs.compare.slidePrev()
    },
    slideNext() {
      this.$refs.carousel.slideNext()
      this.$refs.compare.slideNext()
    },
    onScroll(e) {
      const windowTop = e.target.documentElement.scrollTop
      if(windowTop) {
        const element = document.getElementById('products-list')
        const topMargin = element.getBoundingClientRect().top === 79.5
        this.fixedHeader = topMargin
        this.sizeHeader = topMargin
      }
    },
    hideHeader() {
      this.sizeHeader = !this.sizeHeader
    },
    heightProperties(k) {
      if(this.visibleTable) {
        const el = document.getElementById(`properties-title-${k}`)
        if(el) {
          const height = document.getElementById(`properties-title-${k}`).offsetHeight
          return `${ height }px`
        }
      }
    },
    async selectFavorite(product) {
      const payload = {
        entity: 'product',
        slug: product.item.slug,
      }
      try {
        if(!product.favorite_id) {
          const res = await this.$api.favorites.addFavorites(payload)
          product.favorite_id = res.data.id
        } else {
          await this.$api.favorites.deleteFavorites(product.favorite_id)
          product.favorite_id = null
        }
      } catch (error) {
        // console.log(error)
      }
    },
    async addToCart(compare) {
      const form = {
        entity: compare.entity,
        slug: compare.item.slug,
        ...this.getTempUid,
      }
      await this.$api.cart.addItemCart(form)
      await this.$store.dispatch('cart/FETCH_CART_PRODUCTS')
    },
  },
}
